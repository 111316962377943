import NeuromorphBtn from "components/Button/NeuromorphBtn";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import React, { FC, useEffect } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { FaFlag } from "react-icons/fa";
import useCountryHooks from "hooks/useCountryHooks";
import { reversedIso3, truncate } from "utils/utils";
import { CircularProgress, Typography } from "@mui/material";
import { CountryItem } from "utils/interfaces";
import Spacer from "components/Spacer/Spacer";
import { useTranslation } from "react-i18next";

interface IProps {
  loading?: boolean;
  countries: CountryItem[];
  horizontal?: boolean;
  activeCountry?: CountryItem;
  handleSelect?: Function;
}

const CountryItems: FC<IProps> = ({
  activeCountry,
  horizontal,
  handleSelect,
  countries,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        countries.map((country, index) =>
          horizontal ? (
            <div
              key={country.code}
              style={{
                display: "flex",
                gap: 20,
                flexDirection: "column",
              }}
            >
              <NeomorphButtonBase
                onClick={() => handleSelect?.(country)}
                style={{ height: 40, flex: 1 }}
                background={
                  country.code === activeCountry?.code
                    ? baseStyles.color.blue.primary
                    : baseStyles.color.grey
                }
              >
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    flex: 1,
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: 350,
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={`https://flagcdn.com/h20/${reversedIso3[
                      country.code
                    ]?.toLowerCase()}.png`}
                    width="30"
                    height={25}
                    alt={country.code}
                  />
                  <div style={{ marginLeft: 10, flex: 1, display: "flex" }}>
                    <Typography style={{ textTransform: "none" }}>
                      {`${truncate(country.name, 30)} (${
                        country?.currency?.code ?? t('input.undefined')
                      })`}
                    </Typography>
                  </div>
                </div>
              </NeomorphButtonBase>
            </div>
          ) : (
            <div key={country.code} style={{ display: "flex", gap: 20 }}>
              <NeuromorphBtn
                onClick={() => handleSelect?.(country)}
                label={`${truncate(country.name, 25)} (${
                  country?.currency?.code ?? t('input.undefined')
                })`}
                startIcon={
                  <img
                    src={`https://flagcdn.com/h20/${reversedIso3[
                      country?.code
                    ]?.toLowerCase()}.png`}
                    width="20"
                    alt={country.code}
                  />
                }
                width="auto"
                background={baseStyles.color.grey}
                textColor={baseStyles.text.default}
              />
            </div>
          )
        )
      )}
    </div>
  );
};

export default CountryItems;
