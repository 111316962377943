import React, { useState, useEffect } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import countryStyle from "../CountryStyle";
import Spacer from "components/Spacer/Spacer";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { useTranslation } from "react-i18next";
import useZoneCommission from "hooks/useZoneCommissionHooks";
import { IZoneCommissionPayload } from "utils/interfaces";

const CountryStat = () => {
  const classes = countryStyle();
  const { t } = useTranslation();
  const {
    FetchZoneCommissionPercentages,
    percentages,
    loading,
    UpdateZoneCommissionPercentage
  } = useZoneCommission()

  const [westAfricaSameCurrency, setWestAfricaSameCurrency] = useState("2");
  const [westAfricaDifferentCurrency, setWestAfricaDifferentCurrency] = useState("2");
  const [centralAfricaSameCurrency, setCentralAfricaSameCurrency] = useState("2");
  const [centralAfricaDifferentCurrency, setCentralAfricaDifferentCurrency] = useState("3");
  const [differentCurrencyZones, setDifferentCurrencyZones] = useState("2");

  const handleChangeWestAfricaSameCurrency = (value: string) => {
    setWestAfricaSameCurrency(value);
  };
  
  const handleChangeWestAfricaDifferentCurrency = (value: string) => {
    setWestAfricaDifferentCurrency(value);
  };
  
  const handleChangeCentralAfricaSameCurrency = (value: string) => {
    setCentralAfricaSameCurrency(value);
  };
  
  const handleChangeCentralAfricaDifferentCurrency = (value: string) => {
    setCentralAfricaDifferentCurrency(value);
  };
  
  const handleChangeDifferentCurrencyZones = (value: string) => {
    setDifferentCurrencyZones(value);
  };  

  const handleButtonClick = () => {
    console.log("Values on button click:");
    console.log({
      westAfricaSameCurrency,
      westAfricaDifferentCurrency,
      centralAfricaSameCurrency,
      centralAfricaDifferentCurrency,
      differentCurrencyZones,
    });

    
  };

  const handleUpdatePercentages = () => {
    const updatedValues: IZoneCommissionPayload = {
      westAfricaSameCurrency: westAfricaSameCurrency,
      westAfricaDifferentCurrency: westAfricaDifferentCurrency,
      centralAfricaSameCurrency: centralAfricaSameCurrency,
      centralAfricaDifferentCurrency: centralAfricaDifferentCurrency,
      differentCurrencyZones: differentCurrencyZones,
    };
  
    UpdateZoneCommissionPercentage(updatedValues);
  };

  useEffect(() => {
    FetchZoneCommissionPercentages();
  }, []);

  useEffect(() => {
    if (percentages) {
      let { 
        westAfricaSameCurrency = 2,
        westAfricaDifferentCurrency = 2,
        centralAfricaSameCurrency = 2,
        centralAfricaDifferentCurrency = 3,
        differentCurrencyZones = 2,
      } = percentages;

      setWestAfricaSameCurrency(westAfricaSameCurrency);
      setWestAfricaDifferentCurrency(westAfricaDifferentCurrency);
      setCentralAfricaSameCurrency(centralAfricaSameCurrency);
      setCentralAfricaDifferentCurrency(centralAfricaDifferentCurrency);
      setDifferentCurrencyZones(differentCurrencyZones);
    }
  }, [percentages]);

  return (
    <div>
      <p className={classes.textTitle}>{t('country.transactions')}</p>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>{t('country.africaO')}</p>

            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value={westAfricaSameCurrency}
                label={t('country.sameMoney')}
                onChange={handleChangeWestAfricaSameCurrency}
                withoutPlaceHolder={true}
                unite="%"
              />
              <NeomorphInputWithIcon
                withoutIcon={true}
                value={westAfricaDifferentCurrency}
                label={t('country.diffMoney')}
                onChange={handleChangeWestAfricaDifferentCurrency}
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>{t('country.africaC')}</p>

            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value={centralAfricaSameCurrency}
                label={t('country.sameMoney')}
                onChange={handleChangeCentralAfricaSameCurrency} // Use handler to update state
                withoutPlaceHolder={true}
                unite="%"
              />
              <NeomorphInputWithIcon
                withoutIcon={true}
                value={centralAfricaDifferentCurrency}
                label={t('country.diffMoney')}
                onChange={handleChangeCentralAfricaDifferentCurrency} // Use handler to update state
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>
              {t('country.two')}
            </p>
            <Spacer />
            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value={differentCurrencyZones}
                onChange={handleChangeDifferentCurrencyZones}
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeuromorphBtn
          label={t('country.majPercentage')}
          loading={loading}
          onClick={handleUpdatePercentages}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default CountryStat;
