import { useState, useEffect } from "react";
import ZoneComissionService from "services/Transaction/ZoneComission.service";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useZoneCommission = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [percentages, setPercentages] = useState<any>(null);

  const FetchZoneCommissionPercentages = async () => {
    setLoading(true);

    try {
      const response = await ZoneComissionService.GetZoneCommissionPercentages();
      if (response?.data) {
        setPercentages(response.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t("alert.errorCountry"), {
        id: "Error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchZoneCommissionPercentages();
  }, []);

  const UpdateZoneCommissionPercentage = (
    updatedData: any,
    onDone?: Function
  ) => {
    setLoading(true);

    try {
      
      ZoneComissionService.UpdateZoneCommissionPercentages(
        updatedData
      ).then(response => {
        if (response?.data) {
          toast.success(t("alert.modifiedSuccess"));
          onDone?.();
        }
        setLoading(false);
      }).catch(error => {
        toast.error(t("alert.serverError"), { id: "Error" });
        setLoading(false);
      })
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t("alert.serverError"), { id: "Error" });
      setLoading(false);
    }
  };

  return {
    FetchZoneCommissionPercentages,
    UpdateZoneCommissionPercentage,
    percentages,
    loading,
  };
};

export default useZoneCommission;
