import axios from "services/AxiosInstance";
import { IZoneCommissionPayload } from "utils/interfaces";

const GetZoneCommissionPercentages = () => {
  const url = `/api/transactions/zoneCommissionPercentages`;

  return axios.get(url);
};

const UpdateZoneCommissionPercentages = (payload: IZoneCommissionPayload) => {
  return axios.post(`/api/transactions/updateCommissionPercentages`, {
    ...payload
  })
}

const ZoneComissionService = {
  GetZoneCommissionPercentages,
  UpdateZoneCommissionPercentages
};

export default ZoneComissionService;
