import useUsersHooks from "hooks/useUsersHooks";
import { FC, createContext, useState, useEffect } from "react";
import { ConnectedUserInfos, Currency, IUserItem } from "utils/interfaces";
import {
  getUserStatusStat,
} from "services/User/UserService";

type UserContextType = {
  currentUser: ConnectedUserInfos;
  setCurrentUser: (user: any) => any;
  userList: IUserItem[];
  setUserList: (users: IUserItem[]) => void;
  refreshUserList: Function;
  filteredUsers: IUserItem[];
  setFilteredUsers: (user: IUserItem[]) => void;
  currentCurrency: string;
  setCurrentCurrency: Function;
  customerNotifications: number;
  setCustomerNotifications: (count: number) => void;
};

export const UserContext = createContext<UserContextType>({
  currentUser: {} as ConnectedUserInfos,
  setCurrentUser: (user: any) => {},
  userList: [],
  setUserList: (user: IUserItem[]) => {},
  filteredUsers: [],
  setFilteredUsers: (user: IUserItem[]) => {},
  refreshUserList: () => {},
  currentCurrency: "",
  setCurrentCurrency: () => {},
  customerNotifications: 0,
  setCustomerNotifications: () => {},
});

interface UserProviderProps {
  children: any;
}

export const UserProvider: FC<UserProviderProps> = (
  props: UserProviderProps
) => {
  const [currentUser, setCurrentUser] = useState({} as ConnectedUserInfos);
  const [users, setUsers] = useState<IUserItem[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUserItem[]>([]);
  const [currentCurrency, setCurrentCurrency] = useState("EUR");
  const { children } = props;
  const { performGetUserList } = useUsersHooks({});
  const [customerNotifications, setCustomerNotifications] = useState(0);

  useEffect(() => {
    console.log("User's list upated");
  }, [users]);

  useEffect(() => {
    const fetchCustomerNotifications = async () => {
      try {
        const response = await getUserStatusStat();
        // Assuming `getUserStatusStat` returns an Axios response object
        if (response?.data) {
          const { new: newNotifications = 0, annulation = 0 } = response.data;
          setCustomerNotifications(newNotifications + annulation);
        }
      } catch (error) {
        console.error("Failed to fetch customer notifications:", error);
      }
    };
  
    fetchCustomerNotifications();
  }, []);

  return (
    <UserContext.Provider
      value={{
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
        userList: users,
        setUserList: (_users) => {
          setUsers(_users);
        },
        refreshUserList: () => {
          performGetUserList();
        },
        filteredUsers,
        setFilteredUsers,
        currentCurrency,
        setCurrentCurrency,
        customerNotifications,
        setCustomerNotifications,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
